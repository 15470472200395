import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OutlinedInput from '@mui/material/OutlinedInput';

import apiClient from "./http-common";
import { IconButton } from "@mui/material";


const footers = [
];

function FutureValueContent() {

  const [ProveFutureValue, setProveFutureValue] = useState("");
  const [ProveInterestRate, setProveInterestRate] = useState("");
  const [ProveNumberOfYears, setProveNumberOfYears] = useState("");
  const [ProvePresentValue, setProvePresentValue] = useState("");
  const [ProofResult, setProofResult] = useState("");
  const [VerifyingKeyResult, setVerifyingKeyResult] = useState("");
  const [VerifyInterestRate, setVerifyInterestRate] = useState("");
  const [VerifyNumberOfYears, setVerifyNumberOfYears] = useState("");
  const [VerifyProof, setVerifyProof] = useState("");
  const [VerifyVerifyingKey, setVerifyVerifyingKey] = useState("");
  const [VerifyResult, setVerifyResult] = useState("");
  const [invalidInterest, setInvalidInterest] = useState(false);
  
  const fortmatResponse = (res) => {
    return JSON.stringify(res, null, 2);
  };

  const tiers = [
    {
      title: 'Prove',
      description: [
        <TextField id="ProveFutureValue" label="Future Value" variant="standard" onChange={(newValue) => setProveFutureValue(newValue.target.value)} value={ProveFutureValue} />,
        <TextField id="ProvePresentValue" label="Present Value" variant="standard" onChange={(newValue) => setProvePresentValue(newValue.target.value)} value={ProvePresentValue} />,
        <TextField id="ProveInterestRate" label="Interest Rate" variant="standard" onChange={(newValue) => setProveInterestRate(newValue.target.value)} value={ProveInterestRate} error={invalidInterest} helperText={invalidInterest && "Enter interest as decimal ex 0.05 "} />,
        <TextField id="ProveNumberOfYears" label="Number Of Years" variant="standard" onChange={(newValue) => setProveNumberOfYears(newValue.target.value)} value={ProveNumberOfYears} />,
      ],
      buttonText: 'Generate Proof',
      buttonVariant: 'outlined',
      onClick: () => prove(),
    },
    {
      title: 'Prove API Response',
      description: [
      <FormControl>
        <FormLabel> Proof: </FormLabel>  
        <OutlinedInput
          label="Proof:"
          id="outlined-adornment-password"
          value={ProofResult}
          size="md"
          notched={false}
          minRows={2} 
          maxRows={2}
          multiline
          readOnly
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => copyContent(ProofResult)}
                edge="end"
              >
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormLabel> Verifying Key: </FormLabel>  
        <OutlinedInput
          label="Verifying Key:"
          id="outlined-adornment-password"
          value={VerifyingKeyResult}
          size="md"
          notched={false}
          minRows={2} 
          maxRows={2}
          multiline
          readOnly
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => copyContent(VerifyingKeyResult)}
                edge="end"
              >
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>  
      ],
      buttonText: 'Clear Prove & Response',
      buttonVariant: 'outlined',
      onClick: () => clearProveResponse(),
    },
    {
      title: 'Verify',
      description: [
        <TextField id="VerifyInterestRate" label="Interest Rate" variant="standard" onChange={(newValue) => setVerifyInterestRate(newValue.target.value)} value={VerifyInterestRate} />,
        <TextField id="VerifyNumberOfYears" label="Number Of Years" variant="standard" onChange={(newValue) => setVerifyNumberOfYears(newValue.target.value)} value={VerifyNumberOfYears} />,
        <TextField id="VerifyProof" label="Proof" variant="standard" onChange={(newValue) => setVerifyProof(newValue.target.value)} value={VerifyProof} />,
        <TextField id="VerifyVerifyingKey" label="Verifying Key" variant="standard" onChange={(newValue) => setVerifyVerifyingKey(newValue.target.value)} value={VerifyVerifyingKey} />,
      ],
      buttonText: 'Verify Proof',
      buttonVariant: 'outlined',
      onClick: () => verify(),
    },
    {
      title: 'Verify API Response',
      description: [
      <FormControl>  
        <FormLabel> Response: </FormLabel>  
        <Textarea id="proofReturn" label="Proof:" color="neutral" readOnly minRows={2} maxRows={7} size="md" variant="outlined" value={VerifyResult} />
      </FormControl>  
      ],
      buttonText: 'Clear Verify & Response',
      buttonVariant: 'outlined',
      onClick: () => clearVerifyReponse(),
    },
  ];

  async function copyContent(content) {
    navigator.clipboard.writeText(content)
  }

  async function clearVerifyReponse() {
    setVerifyResult("");
    setVerifyInterestRate("");
    setVerifyNumberOfYears("");
    setVerifyProof("");
    setVerifyVerifyingKey("");
  }

  async function clearProveResponse() {
    setProofResult("");
    setVerifyingKeyResult("");
    setProveFutureValue("");
    setProvePresentValue("");
    setProveInterestRate("");
    setProveNumberOfYears("");
  }

  async function prove() {

    if(ProveInterestRate.includes("%") || parseFloat(ProveInterestRate) > 1)
    {
      setInvalidInterest(true)
    }
    else {
      setInvalidInterest(false)
      setProofResult("Waiting for Response");
      setVerifyingKeyResult("Waiting for Response");
      const FV = parseInt(ProveFutureValue);
      const PV = parseInt(ProvePresentValue);
      const I = parseFloat(ProveInterestRate);
      const N = parseInt(ProveNumberOfYears);
      apiClient.post('/prove', 
      {
        FutureValue: FV,
        PresentValue: PV,
        InterestRate: I,
        NumberOfYears: N
      })
      .then(function (response) {
        setProofResult(fortmatResponse(response.data.proof).slice(1, -1));
        setVerifyingKeyResult(fortmatResponse(response.data.verifyingKey).slice(1, -1));
      })
      .catch(function (error) {
        setProofResult(error.response.data.error);
        setVerifyingKeyResult("See error above")
      });
    }
  }

  async function verify() {
    setVerifyResult("Waiting for Response");
    const I = parseFloat(VerifyInterestRate);
    const N = parseInt(VerifyNumberOfYears);
    const P = (VerifyProof);
    const VK = (VerifyVerifyingKey);
    apiClient.post('/verify', 
    {
      InterestRate: I,
      NumberOfYears: N,
      proof: P,
      verifyingKey: VK
    })
    .then(function (response) {
      setVerifyResult(fortmatResponse(response.data.message));
    })
    .catch(function (error) {
      setVerifyResult(error.response.data.error);
    });
  }

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography align="center" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Financial Circuit Zero-Knowledge Service
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Future Value
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
        After calculating Future Value on your own: <br/> (FV = PV[(1+i)^N]) <br/> Input values into prove function and generate. <br/>
        <br/>
        Take generated proof, verifying key, and public values (interest rate and number of years) and verify the proof. <br/>
        <br/>
        Sample values: Future Value: 6000000000, Present Value:  2345548629, Interest Rate: 0.11,	Number Of Years: 9
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" >
        <Grid container spacing={5} alignItems="center" justifyContent="center" >
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={2}
              md={5}   
            >
              <Card
                sx={{
                  height: "380px",
                  display:'flex', 
                  justiyContent:'space-between', 
                  flexDirection:'column',
                }}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions disableSpacing sx={{ mt: "auto" }}>
                  <Button fullWidth variant={tier.buttonVariant} onClick={tier.onClick} >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}
export default function FutureValue() {
  return <FutureValueContent />;
}