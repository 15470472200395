// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import FutureValue from './FutureValue';
import Resume from './Resume';
import reportWebVitals from './reportWebVitals';

const App = () => {
  const path = window.location.pathname;

  if (path === '/resume') {
    return <Resume />;
  } else {
    return <FutureValue />;
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
