// src/Resume.js

import React, { useEffect } from 'react';

const ResumeContent = () => {
  useEffect(() => {
    // Redirect to the PDF file
    window.location.href = `/resume.pdf`;
  }, []);

  return <div></div>;
};

export default function Resume() {
  return <ResumeContent />;
}
